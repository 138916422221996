import exportObjects from '@/lib/exportObjects';
import XLSX from 'xlsx';
import grid_constants from '@/assets/data/grid_constants';
import moment from 'moment';

const momentFormat = 'DD.MM.YYYY HH-mm';

export default {
  data() {
    return {
      isSpinner: false
    };
  },
  computed: {
    sheetColumns() {
      return Array.from(grid_constants.favouriteApartmentsGrid.filter((el, index) => !el.hidden && index != 0), x => x.title);
    },

    objectsCount() {
      return this.$store.getters.getDatasetInfo.count;
    },

    currentDatetime() {
      const utcTime = moment.utc(new Date());
      return moment(utcTime).local().format(momentFormat);
    },
  },
  methods: {
    exportData() {
      this.isSpinner = true;
      exportObjects(this.objectsCount).then(objects => {
        if (objects) {
          let res = [];
          res.push(this.sheetColumns);
          objects.forEach(el => {
            const elem = el.map((elem, ind) => ind == 1 ? elem.id : elem);

            elem.shift();

            res.push(elem);
          });

          const ws = XLSX.utils.aoa_to_sheet(res);
          const wb = XLSX.utils.book_new();
          const sheetName = `Экспорт на ${this.currentDatetime}`;
          XLSX.utils.book_append_sheet(wb, ws, sheetName);
          XLSX.writeFile(wb, `${sheetName}.xlsx`);

          this.isSpinner = false;
        }
      });
    }
  }
}
