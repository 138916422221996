<script>
  import DataTable from '@/components/DataTable.vue';

  export default {
    computed: {
      scope() {
        return this.$store.getters.getScope;
      }
    },
    components: {
      DataTable
    },
  }
</script>