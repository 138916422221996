import { apolloClient } from './apollo';
import * as internalAdmin from '@/models/internalAdmin';
import BaseDataTable, { startCalculation } from '@/lib/BaseDataTable.js';
import store from '../store';
import grid_constants from '@/assets/data/grid_constants';

const actualDataTableOptions = grid_constants['favouriteApartmentsGrid'].filter(el => !el.hidden);
const inst = new BaseDataTable(actualDataTableOptions);

async function exportObjects(countObjects = 10000) {
  const x = await apolloClient.query({
    query: internalAdmin.favouriteObjects({ showPhones: true }),
    variables: {
      first: countObjects,
      scope: store.getters.getScope,
      calculationPolygonId: store.getters.getPolygonId,
      groupId: store.getters.getGroupId,
      queryId: store.getters.getQueryId,
      fields: store.getters.getFilters,
      orders: store.getters.getOrders,
      orderByCalculationSignals: store.getters.getOrderByCalculationSignals,
      objectType: store.getters.getObjectType,
      distinct: store.getters.getDistinct
    },
  })
  .then((data, loading) => {
    if (!loading) {
      const res = data.data.investmentObjects.nodes;
      const objects = inst.updateData(res, 'favouriteApartmentsGrid', true, true);
      return objects;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

export default exportObjects;
