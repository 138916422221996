<template>
  <div class="main">
    <div class="handsontable-wrap min-height no-style">
      <data-loader v-bind:isLoading="isLoading"
                   innerText="Подождите, данные загружаются"
                   v-bind:isSpinner="true" />
      <div>
        <confirm-modal></confirm-modal>
        <div class="second-head-container"
             ref="secondHead">
          <div class="left-area">
            <filters v-bind:tableOptions="tableOptions"
                     v-bind:canCreateQuery="true"
                     v-bind:filters="getFilters"></filters>
            <dataset-info></dataset-info>
          </div>
          <action-select v-bind:objects="objects"></action-select>
        </div>
        <hot-table ref="hotTableComponent"
                  v-bind:settings="hotSettings"
                  v-bind:width="tableWidth"
                  v-bind:height="tableHeight"
                  v-bind:language="language"
                  v-if="objects">
        </hot-table>
        <p v-if="!objects && !isLoading" class="no-data">Данных не найдено</p>
      </div>
    </div>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n';
import BaseDataTable, { startCalculation } from '@/lib/BaseDataTable.js';
import constants from '@/assets/data/grid_constants';
import * as commonConstants from '@/assets/data/constants';
import * as internalAdmin from '@/models/internalAdmin';
import * as internalAdminMutations from '@/mutations/internalAdmin';
import DataLoader from '@/components/DataLoader.vue';
import Filters from '@/components/Filters.vue';
import ConfirmModal from '@/components/common/ConfirmModal.vue';
import { mapGetters } from 'vuex';
import { apolloClient } from '@/lib/apollo';
import updateParam from '@/mixins/updateParam';
import DatasetInfo from '@/components/DatasetInfo.vue';
import ActionSelect from '@/components/ActionSelect'
import detectMobile from '@/lib/detectMobile';
import datasetInfo from '@/lib/datasetInfo';
import investmentObjectAssignToUsers from '@/lib/investmentObjectAssignToUsers';
import errorHandler from '@/lib/ErrorHandler';
import { ClientDataMapper } from '@/lib/data_mappers/client/client.js';

const HEADERHEIGHT = 51;
const COUNTLOADMOREOBJ = 20;

registerLanguageDictionary(ruRU);

export default {
  props: {
    scope: {
      type: String
    },
    dataTableOptions: {
      type: String
    },
    queryObj: {
      type: String
    },
    orderObj: {
      type: Object,
    },
    objectType: {
      type: String, 
      default: 'all'
    },
    orderSignalsObj: {
      type: Object
    }
  },
  mixins: [updateParam],
  components: {
    HotTable,
    DataLoader,
    Filters,
    ConfirmModal,
    DatasetInfo,
    ActionSelect
  },
  data() {
    return {
      language: ruRU.languageCode,
      selectedRows: null,
      isLoading: false,
      endCursor: null,
      currentOrderIndex: null,
      orderIsChanged: false,
      mobileWidth: 767.98,
      objects: null,
      first: null
    }
  },
  computed: {
    changeFieldsArr() {
      return this.investmentObjectChangeFields || null;
    },

    filterFields() {
      return this.investmentObjectFilterFields || null;
    },

    orderFields() {
      return this.investmentObjectOrderFields || null;
    },

    isMobile() {
      return detectMobile();
    },

    ...mapGetters([
      'getPolygonId',
      'getGroupId',
      'getFilters',
      'getOrders',
      'getOrderByCalculationSignals',
      'getObjects',
      'getEndCursor',
      'getHasNextPage',
      'getDataChangedState',
      'getQueryId',
      'getTableObjects',
      'getCountVisibleInvestmentObjects',
      'getBrokerAssignInfo',
      'getDistinct'
    ]),

    tableWidth() {
      return window.innerWidth;
    },

    tableHeight() {
      return window.innerHeight - HEADERHEIGHT - this.$refs.secondHead.clientHeight;
    },

    hotRef() {
      return !this.isLoading ? this.$refs.hotTableComponent.hotInstance : null;
    },

    actualDataTableOptions() {
      return constants[this.dataTableOptions].filter(el => !el.hidden);
    },

    currentDataTable() {
      return new BaseDataTable(this.actualDataTableOptions);
    },

    autoRowSizePlugin() {
      return !this.isLoading ? this.hotRef.getPlugin('AutoRowSize') : null;
    },

    filtersPlugin() {
      return !this.isLoading ? this.hotRef.getPlugin('filters') : null;
    },

    collapsibleColumnsPlugin() {
      return !this.isLoading ? this.hotRef.getPlugin('collapsibleColumns') : null;
    },

    nestedHeaders() {
      return this.currentDataTable.getNestedHeaders().nestedHeaders;
    },

    collapsibleColumns() {
      return this.currentDataTable.getNestedHeaders().collapsibleColumns;
    },

    fixedColumnsLeft() {
      return document.body.clientWidth > this.mobileWidth ? 1 : 0;
    },

    tableOptions() {
      return constants[this.dataTableOptions]
    },

    hotSettings() {
      return {
        licenseKey: process.env.VUE_APP_HANDSONTABLE_LICENSE_KEY,
        data: this.objects,
        colHeaders: true,
        nestedHeaders: this.nestedHeaders,
        collapsibleColumns: this.collapsibleColumns,
        rowHeaders: true,
        columns: this.currentDataTable.updateColumns(this.changeFieldsArr, this.orderFields),
        columnSorting: true,
        fixedColumnsLeft: this.fixedColumnsLeft,
        colWidths: this.currentDataTable.updateColWidth(),
        viewportColumnRenderingOffset: 50,
        filters: this.currentDataTable.getFilterFields(this.filterFields),
        dropdownMenu: ['filter_by_condition', 'filter_action_bar'],
        invalidCellClassName: 'myInvalidClass',
        afterInit: () => {
          if (this.hotRef != null) {
            this.hotRef.validateCells();
          }
        },
        afterGetColHeader: (col, TH) => {
          const BUTTON_CLASS_NAME = 'changeType';
          const existingButton = TH.querySelector('.' + BUTTON_CLASS_NAME);

          const applyClass = (elem, className) => {
            if (!Handsontable.dom.hasClass(elem, className)) {
              Handsontable.dom.addClass(elem, className);
            }
          }

          if (this.hotRef != null) {
            if (col == this.currentOrderIndex && Object.keys(this.getOrders).length > 0) {
              applyClass(TH, `order-${this.getOrders[0].direction}`);
            }

            if (!this.hotRef.enabled) {
              this.collapsibleColumns.forEach(el => {
                if (col === el.col) {
                  applyClass(TH, 'collapsible-indicator-wrap');
                }
              })

              if (existingButton) {
                if (Object.prototype.toString.call( this.hotRef.getSettings().filters ) === '[object Array]' && this.hotRef.getSettings().filters.indexOf(col) === -1) {
                  existingButton.parentNode.removeChild(existingButton);
                }
              }

              return;
            }
          }
        },
        afterScrollVertically: (e) => {
          const objectsCount = this.objects.length;

          if (!this.isLoading) {
            if(this.autoRowSizePlugin.getLastVisibleRow() === objectsCount - 1 && this.getHasNextPage){
              this.loadMore();
            }
          }
        },
        afterChange: (changes, source) => {
          if (this.hotRef) {
            if (changes) {
              this.selectedRows = Array.from(changes, x => x[0]);

              changes.forEach(el => {
                let changeFields = [];

                const row = el[0];
                const col = el[1];
                const curFieldName = this.actualDataTableOptions[col].value;
                const curFilterName = this.actualDataTableOptions[col].filterName;
                const objId = this.hotRef.getCell(row, 1).textContent;
                const initialFieldVal = el[3] ? el[3].toString() : null;

                const val = this.currentDataTable.getSelectedValue(this.actualDataTableOptions, curFieldName, initialFieldVal, 'text', 'value');
                
                if (curFilterName == 'assignedUser') {
                  changeFields.push({
                    investmentObjectId: objId,
                    query: val
                  });

                  this.hotRef.getCell(row, 1).parentNode.classList.add('disabled');

                  investmentObjectAssignToUsers(changeFields).then((res) => {
                    
                    if (res == undefined) {
                      this.hotRef.getCell(row, col).classList.add('has-error');
                      this.hotRef.getCell(row, 1).parentNode.classList.remove('disabled');
                    } else {
                      this.hotRef.getCell(row, col).classList.remove('has-error');
                      this.hotRef.getCell(row, 1).parentNode.classList.remove('disabled');
                      this.hotRef.getCell(row, col).classList.add('changed');
                    }
                  });

                  return;
                }

                if (curFieldName == 'clientTags' || curFieldName == 'clientPhones') {
                  const clientObj = this.getObjects.filter(el => el.id == objId)[0].client;
                  if (!clientObj) return;
                  const client = this.$store.getters['clients/getClientInstance'] || new ClientDataMapper(apolloClient);
                  const tags = curFieldName == 'clientTags' && val ? val.split(', ') : (curFieldName == 'clientTags' ? [] : undefined);

                  client.investmentObjectChangeClientInfo({
                    investmentObjectId: objId,
                    phone: curFieldName == 'clientPhones' ? val : undefined,
                    tags
                  });

                  return;
                }

                changeFields.push({
                  field: curFieldName,
                  value: val == null ? '' : val
                });

                this.changeFields(objId, changeFields);

                this.hotRef.getCell(row, 1).parentNode.classList.add('disabled');
                this.hotRef.setCellMeta(row, col, 'className', 'changed');
              });

              this.hotRef.render();
            }
          }
        },
        beforeFilter: (conditionsStack) => {
          if (conditionsStack.length == 0) {
            return false;
          } else {
            const index = conditionsStack[0].column;
            const name = this.actualDataTableOptions[index].filterName || this.actualDataTableOptions[index].value;
            const condition = conditionsStack[0].conditions[0].name ? constants.mathConditions.filter(el => el.shortTitle == conditionsStack[0].conditions[0].name)[0].title : '';
            const input = document.querySelector('.htFiltersMenuCondition .htUIInput input');

            const isVal2 = condition == 'between' || condition == 'notBetween'
            const initialFieldVal = condition == 'equal' ? input.value : conditionsStack[0].conditions[0].args[0];
            const initialFieldVal2 = isVal2 ? conditionsStack[0].conditions[0].args[1] : null;
            
            const val = this.currentDataTable.getSelectedValue(this.actualDataTableOptions, name, initialFieldVal, 'text', 'value');
            const val2 = this.currentDataTable.getSelectedValue(this.actualDataTableOptions, name, initialFieldVal2, 'text', 'value');

            const obj = Object.assign({}, obj, { name, condition, value: val, value2: val2 });

            this.$store.commit('changeData', true);

            if (this.getFilters.length > 0) {
              this.updateParam({ storeKey: 'updateFilter', v: obj });
            } else {
              this.updateParam({ storeKey: 'addFilter', v: obj });
            }
          }
          
          return false;
        },
        beforeColumnSort: (column, destinationSortConfigs) => {
          const index = destinationSortConfigs[0].column;
          const field = this.actualDataTableOptions[index].orderName || this.actualDataTableOptions[index].value;
          const direction = destinationSortConfigs[0].sortOrder;

          let resDirection = direction;

          if (this.getOrders.length > 0 && this.getOrders[0].field == field) {
            resDirection = this.getOrders[0].direction == 'asc' ? 'desc' : 'asc';
          }

          const obj = Object.assign({}, obj, { field, direction: resDirection });

          this.updateParam({ storeKey: 'updateOrder', v: obj, type: 'order' });
          this.updateParam({ storeKey: 'resetOrderByCalcSignals' });
          this.updateParam({ delete: true, fieldName: 'order_by_calculation_signal_name' });
          this.updateParam({ delete: true, fieldName: 'order_by_calculation_signal_direction' });
          this.$store.commit('changeData', true);
          this.orderIsChanged = true;

          this.currentOrderIndex = this.actualDataTableOptions.findIndex(el => el.orderName == field || el.value == field);

          return false;
        },
        afterOnCellMouseDown: (event, coords) => {
          if (!this.hotRef) return;
          const objId = coords.row > -1 ? this.hotRef.getCell(coords.row, 1).textContent : null;
          this.collapsibleColumns.forEach(el => {
            const key = el.label;
            const coord = {
              row: el.row,
              col: el.col
            };
            if (el.row == coords.row && el.col == coords.col) {
              if (localStorage.getItem(key) == 'collapsed') {
                localStorage.setItem(key, 'expanded');
                this.collapsibleColumnsPlugin.expandSection(coord);
              } else {
                localStorage.setItem(key, 'collapsed');
                this.collapsibleColumnsPlugin.collapseSection(coord);
              }
            }
          });

          if ((coords.col < 1 || coords.row == -2) && !this.isMobile) {
            const hot = this.hotRef;
            document.querySelector('body').classList.add('handsontable-disable-highlight');
            
            setTimeout(() => {
              hot.deselectCell();
              document.querySelector('body').classList.remove('handsontable-disable-highlight');
            }, 100);
          }

          if (event.target.className == 'btn-handsontable-recalc' && coords.row > -1) {
            this.hotRef.getCell(coords.row, 1).parentNode.classList.add('disabled');
            startCalculation(objId, true).then(res => {
              if (res) {
                this.hotRef.getCell(coords.row, 1).parentNode.classList.remove('disabled');
                this.updateCountVisibleObjects();
              }
            });
          }
        },
        beforeCopy: (data, coords) => {
          if (data[0].length == 0 || data[0].every(el => typeof el != 'string')) return;
          if (data[0].every(el => !el.includes('address'))) return;

          data.forEach((el, index) => {
            const o = JSON.parse(data[index][0]);
            data[index][0] = o.address;
          });
        }
      };
    },

    graphQlVariables() {
      return {
        first: this.countVisibleObjects,
        scope: this.scope,
        calculationPolygonId: this.getPolygonId,
        groupId: this.getGroupId,
        fields: this.getFilters,
        orders: this.getOrders,
        orderByCalculationSignals: this.getOrderByCalculationSignals,
        objectType: this.objectType,
        queryId: this.getQueryId,
        accessScopes: ['investmentObjectEdit'],
        distinct: this.getDistinct
      }
    },

    countVisibleObjects: {
      get() {
        return this.getCountVisibleInvestmentObjects || commonConstants.COUNT_VISIBLE_OBJECTS;
      },
      set(v) {
        this.$store.commit('changeCountVisibleInvestmentObjects', v);
      },
    },

    userHasLimits() {
      return this.getBrokerAssignInfo.settingCountAssignObjectsInDay || this.getBrokerAssignInfo.settingCountObjectsInWork;
    },

    defaultOrder() {
      if (this.orderObj) this.orderObj;

      if (this.userHasLimits) {
        return {
          field: 'assignedUserAtInfo',
          direction: 'desc'
        }
      }
      return {
        field: 'externalFirstCrawlerAt',
        direction: 'desc'
      }
    }
  },
  apollo: {
    listObjects: {
      query: () => internalAdmin.favouriteObjects({ showPhones: false }),
      update: data => data.investmentObjects,
      result({ data, loading }) {
        this.isLoading = loading;
        this.$store.commit('setDatasetInfo', null);
        if (!loading) {
          this.isLoading = loading;

          this.saveData(data);

          this.saveDatasetInfo();
        }
      },
      variables() {
        return this.graphQlVariables;
      },
      skip() {
        return this.getDataChangedState == false && this.getObjects.length > 0;
      },
      notifyOnNetworkStatusChange: true,
    },
    investmentObjectChangeFields: {
      query: internalAdmin.investmentObjectChangeFields,
      update: data => data.investmentObjectChangeFields
    },
    investmentObjectFilterFields: {
      query: internalAdmin.investmentObjectFilterFields,
      update: data => {
        return data.investmentObjectFilterFields;
      }
    },
    investmentObjectOrderFields: {
      query: internalAdmin.investmentObjectOrderFields,
      update: data => data.investmentObjectOrderFields,
    },
  },
  methods: {
    async saveDatasetInfo() {
      await datasetInfo().then(res => {
        if (res) {
          this.$store.commit('setDatasetInfo', res);
        }
      });
    },
    updateCountVisibleObjects() {
      const c = this.getCountVisibleInvestmentObjects + 1;
      this.$store.commit('changeCountVisibleInvestmentObjects', +c);
    },
    async loadObjectsPublicUrl(payload) {
      const x = await apolloClient.query({
        query: internalAdmin.favouriteObjects({ showPhones: false }),
        variables: {
          first: payload.first,
          scope: payload.scope,
          calculationPolygonId: payload.calculationPolygonId,
          groupId: payload.groupId,
          fields: payload.fields,
          orders: payload.orders,
          orderByCalculationSignals: payload.orderByCalculationSignals,
          objectType: payload.objectType,
          cursor: payload.cursor,
          distinct: payload.distinct
        },
      })
      .then((data, loading) => {
        if (!loading) {
          return data;
        }
      })
      .catch((error) => {
        return error;
      });

      return x;
    },
    
    loadMore() {
      this.$store.commit('changeData', false);
      this.isLoading = true;
      const obj = Object.assign({}, this.graphQlVariables, { cursor: this.getEndCursor, first: COUNTLOADMOREOBJ } );
      this.loadObjectsPublicUrl(obj).then(res => {
        if (res) {
          this.isLoading = false;
          this.saveData(res.data);
        }
      })
    },
    changeFields(id, changeFields) {
      this.$apollo.mutate({
        mutation: internalAdminMutations.CHANGE_FIELDS_MUTATIONS,
        variables: {
          investmentObjectId: id,
          changeFields,
          recalc: true
        },
      })
      .then((data, loading) => {
        if (!loading) {
          const status = data.data.investmentObjectChangeFields.status;
          const handler = () => {
            for(var i = 0; i < this.hotRef.countCols(); i++){
              if (document.querySelector('tr.disabled') != null) {
                this.selectedRows.forEach(el => {
                  this.hotRef.getCell(el, 1).parentNode.classList.remove('disabled');
                })
              }
            }

            this.hotRef.render();
          };
          setTimeout(handler, 10);

          if (!status) {
            errorHandler.handleError('Changes could not be added', {
              message: 'Changes could not be added',
            });
            alert('Не удалось добавить изменения. Попробуйте, пожалуйста, немного позже или сообщите администратору');
          }
        }
      })
      .catch((error) => {
        errorHandler.handleError(error, {
          message: 'Changes could not be added',
        });
        alert('Не удалось добавить изменения. Попробуйте, пожалуйста, немного позже или сообщите администратору');
      });
    },
    collapseColumns() {
      this.collapsibleColumns.forEach(el => {
        const key = el.label;
        const coord = {
          row: el.row,
          col: el.col
        };

        if (localStorage.getItem(key) != null) {
          if (localStorage.getItem(key) == 'collapsed') {
            this.collapsibleColumnsPlugin.collapseSection(coord);
          } else {
            this.collapsibleColumnsPlugin.expandSection(coord);
          }
        } else {
          this.collapsibleColumnsPlugin.collapseSection(coord);
          localStorage.setItem(key, 'collapsed');
        }
      })
    },
    saveData(data) {
      const investmentObjects = data.investmentObjects;
      const nodes = investmentObjects.nodes;

      this.$store.commit('updateObjects', nodes);
      this.$store.commit('updateEndCursor', investmentObjects.pageInfo.endCursor);
      this.$store.commit('setHasNextPage', investmentObjects.pageInfo.hasNextPage);

      const tableObjects = this.currentDataTable.updateData(this.getObjects, this.dataTableOptions);
      this.$store.commit('updateTableObjects', tableObjects);
      this.objects = this.getTableObjects;
      
      this.currentDataTable.applyObjects(this.getObjects);

      const handler = () => {
        if (this.objects) {

          if (this.hotRef) {
            this.hotRef.loadData(this.objects);
          }
          
          this.collapseColumns();

          this.getObjects.forEach((el, index) => {
            if (el.changedFields != null) {
              const row = index;

              el.changedFields.forEach(field => {
                const i = this.actualDataTableOptions.findIndex(val => val.value == field);

                if (i != -1) {
                  this.hotRef.setCellMeta(row, i, 'className', 'changed');
                }
              })
            }
          })
        }
      };
      setTimeout(handler, 10);
    },
  },
  async mounted() {
    const hasOrder = this.$route.query.order_name && this.$route.query.order_direction;
    const hasCalculationBySignalsOrder = this.$route.query.order_by_calculation_signal_name && this.$route.query.order_by_calculation_signal_direction;
    if (!hasOrder && !hasCalculationBySignalsOrder) {
      if (this.orderSignalsObj) {
        this.updateParam({ storeKey: 'updateOrderByCalcSignals', v: this.orderSignalsObj, type: 'order_by_calculation_signal' });
      } else if (this.defaultOrder) {
        this.updateParam({ storeKey: 'updateOrder', v: this.defaultOrder, type: 'order' });
      }
    } else if (hasOrder) {
      this.updateParam({ storeKey: 'updateOrder', v: { field: this.$route.query.order_name, direction: this.$route.query.order_direction }, type: 'order' });
      this.updateParam({ storeKey: 'resetOrderByCalcSignals' });
    } else if (hasCalculationBySignalsOrder) {
      this.updateParam({ storeKey: 'updateOrderByCalcSignals', v: { field: this.$route.query.order_by_calculation_signal_name, direction: this.$route.query.order_by_calculation_signal_direction }, type: 'order_by_calculation_signal' });
      this.updateParam({ storeKey: 'resetOrder' });
    }

    this.$store.commit('setObjectType', this.objectType);
  }
};
</script>

<style lang="scss" scoped>
.no-data {
  max-width: 80rem;
  margin: 4rem auto 0;
  background-color: $gray-100;
  text-align: center;
  padding: 2rem 1.5rem;
  font-size: 1.6rem;
  border-radius: $border-radius;
}

.btn-test {
  position: fixed;
  top: 60px;
  left: 200px;
}

.second-head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;

  .left-area {
    display: flex;
    align-items: center;
  }
}

@include media-breakpoint-down(md) {
  .second-head-container {
    display: block;
    padding: 0 1rem 1rem;

    .left-area {
      display: block;
    }
  }
}
</style>