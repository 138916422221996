import { apolloClient } from './apollo';
import * as internalAdmin from '@/mutations/internalAdmin';
import errorHandler from '@/lib/ErrorHandler';

async function createQuery(args) {
  const x = await apolloClient.mutate({
    mutation: internalAdmin.QUERY_CREATE_MUTATION,
    variables: {
      filters: args.filters,
      name: args.name,
      type: args.type,
      default: args.isDefault,
      userId: args.userId
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.queryCreate.status;
    }
  })
  .catch((error) => {
    return errorHandler.handleError(error, args);
  });

  return x;
}

export default createQuery;
