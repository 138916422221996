<template>
  <div class="dataset-info">
    <span v-if="getDatasetInfo.count">Объектов: {{ countTotalObjects }}</span>
    <span v-if="getDatasetInfo.statsGeoUniqCounts">, локаций: {{ statsGeoUniqCounts }}</span>
    <span v-if="getDatasetInfo.statsR2">, R<sup>2</sup>: {{ statsR2 }}</span>
    <span v-if="getDatasetInfo.statsR2ItemsCount"> ({{ statsR2ItemsCount }} за 30 дней)</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import declOfNumber from '@/lib/declOfNumber';
import { formatNumber } from '@/lib/formatNumber.js';

export default {
  computed: {
    ...mapGetters([
      'getDatasetInfo'
    ]),

    countTotalObjects() {
      return formatNumber(this.getDatasetInfo.count);
    },

    statsR2() {
      return `${this.getDatasetInfo.statsR2} %`;
    },

    statsR2ItemsCount() {
      return `${this.getDatasetInfo.statsR2ItemsCount} ${declOfNumber(this.getDatasetInfo.statsR2ItemsCount, ['оценка', 'оценки', 'оценок'])}`;
    },

    statsGeoUniqCounts() {
      return formatNumber(this.getDatasetInfo.statsGeoUniqCounts);
    }
  }
}
</script>

<style lang="scss" scoped>
.dataset-info {
  border-left: 1px solid rgba(0,0,0,0.4);
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 450px;
}

@include media-breakpoint-down(md) {
  .dataset-info {
    border: none;
    padding: 0 0 1rem;
  }
}
</style>