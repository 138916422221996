<template>
  <div class="d-flex align-items-center">
    <input type="text"
          class="form-control form-control-sm w-50px"
          v-model="countVisibleObjects"
          v-bind:class="{'error': $v.countVisibleObjects.$error}">
    <small class="text-muted pl-2">от 0 до 1000</small>
    <button class="btn btn-primary btn-sm ml-2"
            v-on:click.prevent="showInvestmentObjects"
            v-bind:disabled="!countVisibleObjects">Загрузить</button>
  </div>
</template>

<script>
import { minValue, maxValue } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      countVisibleObjects: null,
    }
  },
  validations: {
    countVisibleObjects: {
      minValue: minValue(0),
      maxValue: maxValue(1000)
    },
  },
  methods: {
    showInvestmentObjects() {
      this.$v.countVisibleObjects.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit('changeCountVisibleInvestmentObjects', +this.countVisibleObjects);
      }
    },
  }
}
</script>
