import { apolloClient } from './apollo';
import * as internalAdmin from '@/models/internalAdmin';
import store from '@/store';

async function datasetInfo() {
  const x = await apolloClient.query({
    query: internalAdmin.datasetInfo,
    fetchPolicy: 'network-only',
    variables: {
      scope: store.getters.getScope,
      calculationPolygonId: store.getters.getPolygonId,
      groupId: store.getters.getGroupId,
      queryId: store.getters.getQueryId,
      fields: store.getters.getFilters,
      orders: store.getters.getOrders,
      orderByCalculationSignals: store.getters.getOrderByCalculationSignals,
      objectType: store.getters.getObjectType,
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.investmentObjects;
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

export default datasetInfo;
