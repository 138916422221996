import { apolloClient } from './apollo';
import * as internalAdmin from '@/mutations/internalAdmin';
import errorHandler from '@/lib/ErrorHandler';

async function createQueryFixedForUser(args) {
  const x = await apolloClient.mutate({
    mutation: internalAdmin.QUERY_CREATE_FIXED_FOR_USER_MUTATION,
    variables: {
      filters: args.filters,
      name: args.name,
      userId: args.userId,
      overrideAll: args.overrideAll,
      polygonsString: args.polygonsString
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.queryCreateFixedForUser.status;
    }
  })
  .catch((error) => {
    return errorHandler.handleError(error, args);
  });

  return x;
}

export default createQueryFixedForUser;
