import { apolloClient } from './apollo';
import * as internalAdmin from '@/mutations/internalAdmin';
import errorHandler from '@/lib/ErrorHandler';

async function assignObjects(args) {
  const x = await apolloClient.mutate({
    mutation: internalAdmin.INVESTMENT_OBJECT_ASSIGN_TO_ME_MUTATION,
    variables: {
      fetchCount: args.fetchCount
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data;
    }
  })
  .catch((error) => {
    return errorHandler.handleError(error, args);
  });

  return x;
}

export default assignObjects;
