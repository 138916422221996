import * as internalAdmin from '@/models/internalAdmin';
import * as internalAdminMutations from '@/mutations/internalAdmin';
import { apolloClient } from './../lib/apollo';
import * as constants from '@/assets/data/constants.js';

const fieldsAvailable = [
  {
    value: 'city',
    title: '*Город',
    type: 'string'
  },
  {
    value: 'street',
    title: '*Улица',
    type: 'string'
  },
  {
    value: 'buildingNumber',
    title: '*Номер дома, строение, корпус',
    type: 'string'
  },
  {
    value: 'lat',
    title: 'широта',
    type: 'number'
  },
  {
    value: 'lng',
    title: 'долгота',
    type: 'number'
  },
  {
    value: 'address',
    title: 'Адрес',
    type: 'string'
  },
  {
    value: 'buildingFloorsCount',
    title: '*Этажей всего (от 1 до 1000)',
    type: 'number'
  },
  {
    value: 'floorNumber',
    title: '*Этаж (от 1 до 1000)',
    type: 'number'
  },
  {
    value: 'roomsCount',
    title: '*Комнат (от 1 до 1000)',
    type: 'number'
  },
  {
    value: 'square',
    title: '*Площадь (от 1 до 1000)',
    type: 'number'
  },
  {
    value: 'repairRating',
    title: '*Уровень ремонта (выбор из списка)',
    type: 'string',
    customFormat: (v) => {
      const obj = constants.repairRating.filter(el => el.text.toLowerCase() == v.toLowerCase());
      return obj.length != 0 ? +obj[0].value : null;
    },
  },
  {
    value: 'buildingBuildYear',
    title: 'Год постройки',
    type: 'number'
  },
  {
    value: 'buildingMaterialType',
    title: 'Материал стен',
    type: 'string',
    sourceSelect: constants.materialTypeObj
  },
  {
    value: 'parkingType',
    title: 'Парковка',
    type: 'string',
    sourceSelect: constants.parkingType
  },
  {
    value: 'timeWalkToMetro',
    title: 'Пешком до метро, минут',
    type: 'number'
  },
  {
    value: 'objectType',
    title: 'Апартаменты?',
    customFormat: (v) => v == 'Нет' ? 'flat' : 'apartments',
  },
  {
    value: 'jkName',
    title: 'Название ЖК',
    type: 'string'
  },
  {
    value: 'squareLiving',
    title: 'Площадь жилая',
    type: 'number'
  },
  {
    value: 'squareKitchen',
    title: 'Площадь кухни',
    type: 'number'
  },
  {
    value: 'balconiesCount',
    title: 'Количество балконов',
    type: 'number'
  },
  {
    value: 'loggiasCount',
    title: 'Количество лоджий',
    type: 'number'
  },
  {
    value: 'elevatorFreightsCount',
    title: 'Количество грузовых лифтов',
    type: 'number'
  },
  {
    value: 'ceilingHeight',
    title: 'Высота потолков, м',
    type: 'number'
  },
  {
    value: 'bathroomCombined',
    title: 'Количество совмещенных санузлов',
    type: 'number'
  },
  {
    value: 'bathroomShared',
    title: 'Количество раздельных санузлов',
    type: 'number'
  },
  {
    value: 'viewFromWindows',
    title: 'Куда выходят окна?',
    type: 'string'
  },
  {
    value: 'kadNumber',
    title: 'Кадастровый номер квартиры',
    type: 'string'
  },
  // {
  //   value: 'checkViewRoadNoiseWhenWindowOpen',
  //   title: 'При открытом окне шум от дороги',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
  // {
  //   value: 'checkViewEntranceNotGood',
  //   title: '"Убитый" подъезд',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
  // {
  //   value: 'checkViewBadViewFromWindow',
  //   title: 'Вид из окна на ЖД, промзону, и т.п.',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
  // {
  //   value: 'checkViewMinorRedevelopment',
  //   title: 'Незначительная или узаконенная перепланировка',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
  // {
  //   value: 'checkViewAllRoomsHaveWindow',
  //   title: 'Все комнаты с окнами',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
  // {
  //   value: 'checkUnlawfulRedevelopment',
  //   title: 'Значительная неузаконенная перепланировка',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
  // {
  //   value: 'checkHasRegisteredChildren',
  //   title: 'Прописаны дети',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
  // {
  //   value: 'checkProxySale',
  //   title: 'Продажа по доверенности',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
  // {
  //   value: 'checkLegacyLessThanThreeYears',
  //   title: 'Наследство менее 3 лет',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
  // {
  //   value: 'checkNotFullPrice',
  //   title: 'Не полная цена в ДКП',
  //   sourceSelect: constants.dataAvailabilityObj
  // },
];

class Sheet {
  #graphQlFields = null;
  #calcIds = [];
  #calcIdsDone = [];
  #calcIdsFailed = [];
  #groupId = null;
  #externalSourceUrls = [];
  #checkStatusInterval = null;
  #callbackStatus = null;
  #hasCorrectData = false;

  constructor(groupId, hasCorrectData) {
    this.#groupId = groupId;
    this.#hasCorrectData = hasCorrectData;
  }

  get graphQlFields() {
    return this.#graphQlFields;
  }

  get countObjects() {
    return this.#graphQlFields.length;
  }

  get countObjectsDone() {
    return this.#calcIdsDone.length;
  }

  get countObjectsFailed() {
    return this.#calcIdsFailed.length;
  }

  get calcIds() {
    return this.#calcIds;
  }

  get externalSourceUrls() {
    return this.#externalSourceUrls;
  }

  get callbackStatus() {
    return this.#callbackStatus;
  }

  set callbackStatus(val) {
    this.#callbackStatus = val;
  }

  applyFields(fields) {
    this.fieldsMapping(fields);
  }

  fieldsMapping(fields) {
    const firstSheet = fields[0];
    const columns = firstSheet[0];
    this.#externalSourceUrls = fields[1] ? fields[1].filter((el, i) => i != 0) : [];
    const tbody = firstSheet ? firstSheet.filter((el, i) => i != 0) : [];

    const firstSheetArr = tbody.length != 0 ? tbody.map(row => {
      let targetObj = {};
      let address = [];
      let roomsCount = 1;
      let city = '';
      let buildingFloorsCount = null;

      row.forEach((e, i) => {
        const fieldIsExist = fieldsAvailable.filter(elem => elem.title == columns[i]).length != 0;
        let key;
        let type;
        let value;

        if (fieldIsExist) {
          const field = fieldsAvailable.filter(elem => elem.title == columns[i])[0];

          key = field.value;
          type = field.type;

          if (type == 'number') {
            value = +e;
          } else if (field.customFormat) {
            value = field.customFormat(e);
          } else if (field.sourceSelect) {
            const filteredSelect = field.sourceSelect.filter(el => el.text.toLowerCase() == e.toLowerCase());
            value = filteredSelect.length != 0 ? filteredSelect[0].value : field.sourceSelect[0].value;
          } else {
            value = e;
          }
        }

        if (key == 'street' || key == 'buildingNumber') {
          address.push(e);
          targetObj.address = address.join(', ');
        } else if (key != null) {
          targetObj[key] = value;
        }
      });

      if (targetObj.square < 45) {
        roomsCount = 1;
      } else if (targetObj.square >= 45 && targetObj.square < 65) {
        roomsCount = 2;
      } else {
        roomsCount = 3;
      }

      if (targetObj.address) {
        city = targetObj.address.split(',')[1];
      }

      if (targetObj.floorNumber) {
        buildingFloorsCount = +(targetObj.floorNumber * 1.5).toFixed(0);
      }

      targetObj.roomsCount = targetObj.roomsCount || roomsCount;
      targetObj.city = targetObj.city || city;
      targetObj.objectType = targetObj.objectType || 'flat';
      targetObj.buildingFloorsCount = targetObj.buildingFloorsCount || buildingFloorsCount;
      targetObj.hasCorrectData = this.#hasCorrectData;

      return targetObj;
    }) : [];

    const secondSheetArr = this.#externalSourceUrls.length != 0 ? this.#externalSourceUrls.map(el => {
      return { externalSourceUrl: el[0] };
    }) : [];

    this.#graphQlFields = [...firstSheetArr, ...secondSheetArr];
  }

  async createCalcClaimMutation(claim, externalSourceUrl, groupIds) {
    const x = await apolloClient.mutate({
      mutation: internalAdminMutations.CREATE_CALCULATION_CLAIM_MUTATION,
      variables: {
        claim,
        externalSourceUrl,
        groupIds
      },
    })
    .then((data, loading) => {
      if (!loading) {
        return data.data.createCalculationClaim.calcId;
      }
    })
    .catch((error) => {
      return error;
    });
  
    return x;
  }

  async calculationClaimStatus(ids) {
    const x = await apolloClient.query({
      query: internalAdmin.calculationClaimStatus,
      variables: {
        ids
      },
    })
    .then((data, loading) => {
      if (!loading) {
        return data.data.calculationClaimStatus;
      }
    })
    .catch((error) => {
      return error;
    });
  
    return x;
  }

  fireCallbackDone() {
    if (!this.#callbackStatus || this.countObjects == 0) return;

    this.#callbackStatus({
      totalCount: this.countObjects,
      doneCount: this.countObjectsDone,
      failedCount: this.countObjectsFailed
    })
  }

  async createCalcClaim() {
    this.#calcIds = [];
    this.#calcIdsDone = [];
    this.#calcIdsFailed = [];
    let groupIds = [];
    groupIds.push(this.#groupId);

    this.startCheckStatusInterval();

    for (const obj of this.#graphQlFields) {
      
      const investmentObj = obj.objectType ? obj : null;
      const externalSourceUrl = obj.externalSourceUrl ? obj.externalSourceUrl : '';

      await this.createCalcClaimMutation(investmentObj, externalSourceUrl, groupIds).then(res => {
        if (res) {
          this.#calcIds.push(res);
        }
      });
    }

    this.fireCallbackDone();
  }

  startCheckStatusInterval() {
    this.#checkStatusInterval = setInterval(this.checkClaimStatus.bind(this), 4000);
  }

  async checkClaimStatus() {
    
    let calcIdsNew = this.#calcIds.filter(el => !this.#calcIdsDone.includes(el) && !this.#calcIdsFailed.includes(el));
    console.log('calcIdsNew');
    console.log(calcIdsNew);

    if (calcIdsNew.length == 0) return;

    calcIdsNew.push(new Date().getTime().toString());

    await this.calculationClaimStatus(calcIdsNew).then(res => {
      console.log('res');
      console.log(res);
      res.forEach(el => {
        if (el.status == 'done') {
          this.#calcIdsDone.push(el.id);
        } else if (el.status == 'error') {
          this.#calcIdsFailed.push(el.id);
        }
      })
      this.fireCallbackDone();
      console.log('this.#calcIdsDone');
      console.log(this.#calcIdsDone);
    })
  }

  async getStatus() {
    return await new Promise((resolve) => {
      this.createCalcClaim()
        .then(res => {
          console.log('[[ calcIds ]]');
          console.log(this.#calcIds);

          let checkInterval = setInterval(() => {
            
            if (this.countObjects == (this.countObjectsDone + this.countObjectsFailed)) {
              if (this.#checkStatusInterval) clearInterval(this.#checkStatusInterval);
              clearInterval(checkInterval);
              resolve();
            }
          }, 4000);
        });
    })
  }
}

export default Sheet;