<template>
  <div class="filters-area">
    <ul class="list-filters" v-if="filters">
      <button v-for="item in filters"
              v-bind:key="item.value"
              class="btn btn-info btn-sm mr-2"
              v-on:click="deleteFilter(item)"
              v-html="getFilterItemText(item)">
        <span class="delete"></span>
      </button>
      <button class="btn btn-primary btn-sm mr-1"
              v-on:click="resetFilters"
              v-bind:disabled="Object.keys(filters).length == 0"
              v-if="canCreateQuery">
        <svg-icon name="refresh"
                  class="mr-1"></svg-icon>
        Сбросить
      </button>
      <button class="btn btn-primary btn-sm"
              v-on:click="openQueryModal"
              v-bind:disabled="Object.keys(filters).length == 0"
              v-if="canCreateQuery">
        <svg-icon name="save"
                  class="mr-1"></svg-icon>
        Сохранить
      </button>
    </ul>
    <create-query-modal ref="createQueryModal"
                        v-on:createQuery="createQueryInner"
                        v-on:createQueryFixedForUser="createQueryFixedForUserInner"
                        v-on:restoreQueryStatus="restoreQueryStatus"
                        v-bind:queryStatus="queryStatus"></create-query-modal>
  </div>
</template>

<script>
import gridConstants from '@/assets/data/grid_constants';
import * as constants from '@/assets/data/constants';
import updateParam from '@/mixins/updateParam';
import updateQuery from '@/mixins/updateQuery';
import CreateQueryModal from '@/components/common/CreateQueryModal.vue';
import createQuery from '@/lib/createQuery';
import createQueryFixedForUser from '@/lib/createQueryFixedForUser';
import moment from 'moment';
import errorHandler from '@/lib/ErrorHandler';

export default {
  props: {
    tableOptions: {
      type: [Object, Array]
    },
    filters: {
      type: [Object, Array]
    },
    canCreateQuery: Boolean
  },
  components: {
    CreateQueryModal
  },
  mixins: [updateParam, updateQuery],
  data() {
    return {
      queryItems: this.$route.query,
      queryStatus: 'default'
    }
  },
  computed: {
    dateFiltersArr() {
      let arr = [];
      for (const [key, value] of Object.entries(constants.dateFilters)) {
        arr.push(key);
        arr.push(value);
      }
      return arr;
    }
  },
  methods: {
    resetFilters() {
      this.updateParam({ storeKey: 'resetFilters', deleteAll: true, fields: this.filters });
      this.$store.commit('setQueryId', null);
      this.$store.commit('changeData', true);
    },
    deleteFilter(item) {
      this.updateParam({ storeKey: 'deleteFilter', v: item, delete: true, fieldName: item.name });
      this.$store.commit('setQueryId', null);
      this.$store.commit('changeData', true);
    },
    getFilterItemText(item) {
      const name = item.name;
      const obj = this.tableOptions.filter(el => el.filterName == name || el.value == name);
      let nameRU = !name ? '' : obj[0].filterTitle || obj[0].title;
      const isDate = obj[0].hasOwnProperty('customType') && obj[0].customType == 'dateTimeCustom';
      const symbol = gridConstants.mathConditions.filter(el => el.title == item.condition)[0].symbol;
      const value = item.value;
      const value2 = item.value2;

      if (name == 'discountFromPriceMarket' && value != 'NaN') {
        return `${nameRU} ${symbol} ${(value * 100)}% ${value2 ? `и ${value2 * 100}%` : ''}`;
      }

      if (value2) {
        return `${nameRU} ${symbol} ${value} и ${value2}`;
      }

      if (value == 'undefined' || value == 'NaN') {
        return `${nameRU} ${symbol}`;  
      }

      if (isDate) {
        if (!value) return;
        const utcTime = moment.utc(value);

        const arr = value.split(/[.+-]/g);
        if (arr.length < 3 || this.dateFiltersArr.includes(arr[0].trim())) {
          let v;
          let newArr = value.split(' ');
          if (value in constants.dateFilters) {
            v = constants.dateFilters[value];
          } else if (!isNaN(value)) {
            const days = value.includes('+') ? value : `-${value}`;
            v = moment(moment.utc(new Date())).add(days, 'days').local().format('DD.MM.YYYY');
          } else if (isNaN(value) && newArr.length > 1 && ['-', '+'].includes(newArr[1].trim())) {
            const days = newArr[1].includes('+') ? newArr[2] : `-${newArr[2]}`;
            v = moment(moment.utc(new Date())).add(days, 'days').local().format('DD.MM.YYYY');
          } else {
            v = value;
          }

          return `${nameRU} ${symbol} ${v}`;  
        }

        return `${nameRU} ${symbol} ${moment(utcTime).local().format('DD.MM.YYYY')}`;  
      }

      return `${nameRU} ${symbol} ${value}`;
    },
    openQueryModal() {
      this.$refs.createQueryModal.openModal();
    },
    showError(error, obj) {
      errorHandler.handleError(error, obj);

      this.queryStatus = 'error';
    },

    createQueryInner(obj) {
      this.queryStatus = 'loading';
      
      createQuery({
        filters: this.filters,
        name: obj.name,
        type: obj.type,
        isDefault: false,
        userId: obj.userId
      }).then(status => {
        if (status) {
          this.queryStatus = status ? 'success': 'error';
        } else {
          const err = `Query error. Status ${status}`;
          this.showError(err, obj);
        }

        if (status.error) {
          this.showError(status.error, obj);
        }
      });
    },
    createQueryFixedForUserInner(obj) {
      this.queryStatus = 'loading';

      createQueryFixedForUser({
        filters: this.filters,
        name: obj.name,
        userId: obj.userId,
        overrideAll: obj.overrideAll,
        polygonsString: obj.polygonsString
      }).then(status => {
        if (status) {
          this.queryStatus = status ? 'success': 'error';
        } else {
          const err = `Query fixed fo user error. Status ${status}`;
          this.showError(err, obj);
        }

        if (status.error) {
          this.showError(status.error, obj);
        }
      });
    },
    restoreQueryStatus() {
      this.queryStatus = 'default';
    }
  }
}
</script>
