import queries from '@/lib/queries';
import deleteQuery from '@/lib/deleteQuery';
import updateParam from '@/mixins/updateParam';
import { mapGetters } from 'vuex';

export default {
  mixins: [updateParam],
  data() {
    return {
      queryVal: '',
      queriesList: []
    }
  },
  computed: {
    ...mapGetters([
      'getFilters',
      'getQueryId'
    ]),
    query: {
      get() {
        return this.queryVal;
      },
      set(v) {
        this.queryVal = v;
      }
    }
  },
  methods: {
    updateQuery(id) {
      if (this.queriesList.length == 0 || !id ) return;
      const filters = this.queriesList.filter(el => el.id == id)[0].filters;
      const filtersWithoutTypeName = filters.map(el => {
        return {
          condition: el.condition,
          name: el.name,
          value: el.value,
          value2: el.value2
        }
      });
      this.$store.commit('setQueryId', id);

      this.updateParam({ storeKey: 'resetFilters', deleteAll: true, fields: this.getFilters });
      this.updateParam({ storeKey: 'applyFilter', values: filtersWithoutTypeName });
      this.$store.commit('changeData', true);
    },
    loadQueries() {
      queries().then(res => {
        if (res) {
          const defaultId = res.filter(el => el.default).length > 0 ? res.filter(el => el.default)[0].id : null;
          this.queryVal = this.getQueryId || defaultId;
          this.queriesList.push(...res);
          this.updateQuery(this.queryVal);
        }
      });
    },
    deleteQueryInner() {
      deleteQuery({id: this.queryVal}).then(status => {
        if (status) {
          if (this.queriesList.length == 0) {
            this.queryVal = '';
            return;
          }

          this.queriesList = this.queriesList.filter(el => el.id != this.queryVal);

          if (this.queriesList.length != 0) {
            this.queryVal = this.queriesList[0].id;
          }
        } else {
          alert('Не удалось удалить запрос')
        }
      });
    }
  },
}
