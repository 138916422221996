<template>
  <div class="select-wrap">
    <div v-if="queriesList.length > 0"
         class="d-inline-flex">
      <select class="custom-select"
              v-model="query">
        <option v-for="el in queriesList"
                v-bind:key="el.id"
                v-bind:value="el.id">
          {{ el.name }}
        </option>
      </select>
      <a v-bind:class="[`btn btn-primary btn-sm text-white ml-2`, { disabled: !query }]"
         v-on:click.prevent="updateQueryInner">Применить</a>
      <a v-bind:class="[`btn btn-danger btn-sm text-white ml-2`, { disabled: !query }]"
         v-on:click.prevent="deleteQuery">Удалить</a>
    </div>
    <span v-else>Нет сохраненных запросов</span>
  </div>
</template>

<script>
import updateQuery from '@/mixins/updateQuery';

export default {
  mixins: [updateQuery],
  methods: {
    updateQueryInner() {
      this.updateQuery(this.query);
    },
    deleteQuery() {
      this.deleteQueryInner();
    }
  },
  mounted() {
    this.loadQueries();
  }
};
</script>

<style lang="scss" scoped>
  .custom-select {
     height: 2.4rem;
     padding: 0 2.8rem 0 1rem;
  }

  .btn {
    white-space: nowrap;
  }
</style>
