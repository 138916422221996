import gridConstants from '@/assets/data/grid_constants.js';
import * as constants from '@/assets/data/constants.js';
import createQueryFixedForUser from '@/lib/createQueryFixedForUser';
import getUrlParams from '@/lib/getUrlParams';
import store from '@/store';
import user from '@/lib/data_mappers/user.js';
import errorHandler from '@/lib/ErrorHandler';

class Sheet {
  #users = null;
  #listFilters = [];
  #availableUsers = store.getters['users/getUsers'];
  #callbackStatus = null;
  #isLoading = false;

  get isLoading() {
    return this.#isLoading;
  }

  get callbackStatus() {
    return this.#callbackStatus;
  }

  set callbackStatus(val) {
    this.#callbackStatus = val;
  }

  async applyFields(fields) {
    if (this.#availableUsers.length != 0) return;

    const x = await this.getListUsers().then((res) => {
      if (res) {
        store.commit('users/addUsers', res);
        this.#availableUsers = res;
        this.fieldsMapping(fields);
      }
    });

    return x;
  }

  fireCallbackDone() {
    if (!this.#callbackStatus) return;

    this.#callbackStatus({
      isLoading: this.isLoading
    })
  }

  parseUrl(str) {
    const params = getUrlParams(str);
    const availableFilters = Array.from(gridConstants['favouriteApartmentsGrid'], el => el.value);

    const filters = Object.entries(params).map(entry => {
      const [key, value] = entry;

      if (!availableFilters.includes(key)) return;

      const name = key;
      const valArr = key == 'calculationSignals' ? value.replace(/\_/,'&').split('&') : value.split('_');
      const condition = valArr[0];
      const value1 = decodeURIComponent(valArr[1]);
      const value2 = valArr.length == 3 ? valArr[2] : null;

      const objInner = Object.assign({}, objInner, { name, condition, value: value1, value2 });

      return objInner;
    }).filter(el => el);

    this.#listFilters = filters;

    return this.#listFilters;
  }

  async getListUsers() {
    const x = await user.getListUsers({
      first: constants.COUNT_VISIBLE_USERS,
      fields: []
    }).then((res) => {
      return res;
    });

    return x;
  }
  

  fieldsMapping(fields) {
    this.#listFilters = this.parseUrl(fields[0][0][1]);
    const users = fields[0].map((row, index) => {
      if (index < 2) return;

      let obj = {};

      const userObj = this.#availableUsers.filter(el => el.email == row[0]);

      if (userObj.length == 0) return;

      const userId = userObj[0].id;
      const firstName = userObj[0].firstName;
      const lastName = userObj[0].lastName;

      obj.userId = userId;
      obj.polygonsString = row[1];
      obj.contactTelegram = row[2];
      obj.countObjectsAssignedInDay = +row[3];
      obj.countObjectsInWork = +row[4];
      obj.firstName = firstName;
      obj.lastName = lastName;

      return obj;
    }).filter(el => el);

    this.#users = [...users];
  }

  showError(error, obj) {
    errorHandler.handleError(error, obj);
  }

  saveQuery(resolve, reject) {
    if (!this.#users) return;
    
    this.#users.forEach(obj => {
      createQueryFixedForUser({
        filters: this.#listFilters,
        name: `GeneratedFilter${obj.userId}`,
        userId: obj.userId,
        overrideAll: true,
        polygonsString: obj.polygonsString
      }).then(status => {
        if (status) {
          resolve(status);
        } else {
          const err = `Query fixed fo user error. Status ${status}`;
          reject();
          this.showError(err, obj);
        }
      });
    })
  }

  updateUser(resolve, reject) {
    if (!this.#users) return;

    this.#users.forEach(obj => {
      user.update({
        id: obj.userId,
        firstName: obj.firstName,
        lastName: obj.lastName,
        settingCountAssignObjectsInDay: obj.countObjectsAssignedInDay,
        settingCountObjectsInWork: obj.countObjectsInWork,
        contactTelegram: obj.contactTelegram
      }).then(status => {
        if (status) {
          resolve(status.data.userUpdate.status);
        } else {
          const err = `Query fixed fo user error. Status ${status}`;
          reject();
          this.showError(err, obj);
        }
      })
      .catch((error) => {
        this.showError(error, obj);
      });
    })
  }

  async getStatus(data) {
    const p0 = await this.applyFields(data);

    const p1 = new Promise((resolve, reject) => {
      this.saveQuery(resolve, reject);
    });

    const p2 = new Promise((resolve, reject) => {
      this.updateUser(resolve, reject);
    });

    return await new Promise((resolve) => {
      Promise.all([p0, p1, p2]).then(values => {
        resolve();
      });
    })
  }
}

const sheet = new Sheet();

export default sheet;
