import * as internalAdmin from '@/models/internalAdmin';
import * as internalAdminMutations from '@/mutations/internalAdmin';
import { apolloClient } from './../lib/apollo';
import * as constants from '@/assets/data/constants.js';
import grid_constants from '@/assets/data/grid_constants.js';
import moment from 'moment';

const momentFormat = 'DD.MM.YYYY HH:mm';

class Sheet {
  #graphQlFields = null;
  #calcIdsDone = [];
  #calcIdsFailed = [];

  get graphQlFields() {
    return this.#graphQlFields;
  }

  get countObjects() {
    return this.#graphQlFields.length;
  }

  get countObjectsDone() {
    return this.#calcIdsDone.length;
  }

  get countObjectsFailed() {
    return this.#calcIdsFailed.length;
  }

  applyFields(fields) {
    this.fieldsMapping(fields);
  }

  fieldsMapping(fields) {
    const firstSheet = fields[0];
    const columns = firstSheet[0];
    const tbody = firstSheet ? firstSheet.filter((el, i) => i != 0) : [];

    const firstSheetArr = tbody.length != 0 ? tbody.map(row => {
      let targetObj = {};

      row.forEach((e, i) => {
        const fieldIsExist = grid_constants.favouriteApartmentsGrid.filter(elem => elem.title == columns[i]).length != 0;
        let key;
        let type;
        let value;

        if (fieldIsExist) {
          const field = grid_constants.favouriteApartmentsGrid.filter(elem => elem.title == columns[i])[0];

          key = field.mutationFieldName || field.value;
          type = field.type;

          if (type == 'numeric') {
            value = +e;
          } else if (field.sourceObj == 'undefined' && field.source) {
            value = field.source.filter(el => el.toLowerCase() == e.toLowerCase());
          } else if (field.sourceObj) {
            const filteredSelect = field.sourceObj.filter(el => el.text.toLowerCase() == e.toLowerCase());
            value = filteredSelect.length != 0 ? filteredSelect[0].value : field.sourceObj[0].value;
          } else if (type == 'checkbox') {
            value = e.toLowerCase() == 'да'
          } else if (field.formComponent == 'datetime') {
            value = moment.utc(e).local().format(momentFormat);
          } else {
            value = e;
          }
        }

        if (key != null) {
          targetObj[key] = value;
        }
      });

      return targetObj;
    }) : [];

    this.#graphQlFields = [...firstSheetArr];
  }

  getObjectId(externalUrl) {
    const x = apolloClient.query({
      query: internalAdmin.objectId(),
      variables: {
        fields: [
          {
            name: 'externalUrl',
            condition: 'equal',
            value: externalUrl,
            value2: null
          },
        ]
      }
    })
    .then((data, loading) => {
      if (!loading) {
        const nodes = data.data.investmentObjects.nodes;
        const resIsNotEmpty = nodes.length > 0;
        return resIsNotEmpty ? nodes[0].id : null;
      }
    })
    .catch((error) => {
      return error;
    });
  
    return x;
  }

  updateObjectData(id, changeFields) {
    const x = apolloClient.mutate({
      mutation: internalAdminMutations.CHANGE_FIELDS_MUTATIONS,
      variables: {
        investmentObjectId: id,
        changeFields,
        recalc: true
      },
    })
    .then((data, loading) => {
      if (!loading) {
        return data.data.investmentObjectChangeFields.status;
      }
    })
    .catch((error) => {
      return error;
    });

    return x;
  }

  async getStatus() {
    return await new Promise((resolve) => {
      this.#graphQlFields.forEach((el, index, arr) => {
        let changeFields = [];
        for (let [field, value] of Object.entries(el)) {
          if (field != 'externalUrl' && field != 'id') {
            changeFields.push({
              field,
              value: value.toString()
            })
          }
        }

        const fn = (id) => {
          this.updateObjectData(id, changeFields).then((status) => {
            if (status) this.#calcIdsDone.push(id)
            else this.#calcIdsFailed.push(id);
            
            if ((this.countObjectsDone + this.countObjectsFailed) == this.countObjects) {
              resolve({
                countObjectsDone: this.countObjectsDone,
                countObjectsFailed: this.countObjectsFailed,
                countObjects: this.countObjects
              });
            }
          });
        }

        if (el.id) return fn(el.id);

        this.getObjectId(el.externalUrl).then((id) => {
          fn(id);
        });
      });
    })
  }
}

export default Sheet;