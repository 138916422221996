<template>
  <div class="d-flex align-items-center">
    <input type="text"
          class="form-control form-control-sm w-50px"
          v-model.number="fetchCount">
    <button class="btn btn-primary btn-sm ml-2"
            v-on:click.prevent="assignInvestmentObjectsToMe"
            v-bind:disabled="!fetchCount">Добавить</button>
  </div>
</template>

<script>
import investmentObjectAssignToMe from '@/lib/investmentObjectAssignToMe';
import auth from '@/lib/Auth.js';
import { mapGetters } from 'vuex';
import errorHandler from '@/lib/ErrorHandler';

const ERROR_MESSAGES = {
  'InternalAdmin__InvestmentObject__AssignToMe__ErrorReachLimitInWork': 'Превышен лимит объектов в работе',
  'InternalAdmin__InvestmentObject__AssignToMe__ErrorReachLimitToDay': 'Превышен лимит объектов в день'
};

export default {
  data() {
    return {
      fetchCount: 1
    }
  },
  computed: {
    ...mapGetters([
      'getCountVisibleInvestmentObjects'
    ]),
  },
  methods: {
    errorMessage(args) {
      return {
        message: ERROR_MESSAGES[args.__typename],
        limit: args.limit,
        count: args.count
      }
    },

    showError(err) {
      this.fetchCount = 1;
      errorHandler.handleError('There is a problem with investmentObjectAssignToMe mutation', err);

      const errorDescription = this.errorMessage(err);

      alert(`${errorDescription.message}. Лимит: ${errorDescription.limit}. Кол-во: ${errorDescription.limit}`);
    },

    showErrorHasNoObjects() {
      this.fetchCount = 1;
      errorHandler.handleError('No objects found by your request. investmentObjectAssignToMe mutation', { uid: auth.uid });

      alert('Не найдено объектов по заданным критериям');
    },

    assignInvestmentObjectsToMe() {
      investmentObjectAssignToMe({
        fetchCount: this.fetchCount,
      }).then(res => {
        if (res) {
          if (res.investmentObjectAssignToMe.error) {
            this.showError(res.investmentObjectAssignToMe.error);
            return;
          }
          if (!res.investmentObjectAssignToMe.investmentObjects) {
            this.showErrorHasNoObjects();
            return;
          }
          const countObjects = res.investmentObjectAssignToMe.investmentObjects.length;
          const c = this.getCountVisibleInvestmentObjects + countObjects;
          this.$store.commit('changeCountVisibleInvestmentObjects', +c);
          this.fetchCount = 1;
        }
      });
    }
  }
}
</script>
